body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.half_width {
  width: 50%;
}

.center-grid {
  justify-content: center;
}

h6.title-text {
  font-weight: bold;
  text-decoration: underline;
}

.top-space {
  padding-top: 20px;
}

div.small-margins {
  margin: 6px;
}
