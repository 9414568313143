.calc-report-container .MuiTypography-caption {
  margin-bottom: 2em;
}

h3 {
  break-before: always;
}

h3.no-break {
  break-before: avoid;
}

.gen-member-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  page-break-inside: avoid;
}

.gen-member-pic {
  width: 50%;
  border-style: solid;
}

.gen-member-pic img {
  width: 100%;
}

.gen-member-notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gen-member-note {
  display: flex;
  align-items: center;
  margin: 0.25em;
}

.symbol-pic {
  width: 2em;
}

.equation-div {
  text-align: center;
  font-style: italic;
}

.equation-div p {
  margin: 0;
}

.small-equation {
  font-size: 0.75em;
}

.center-align {
  align-items: center;
  text-align: center;
}

.matrix-div {
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;
  page-break-inside: avoid;
}

.matrix-div:before,
.matrix-div:after {
  content: "";
  position: absolute;
  top: 0;
  border: 1px solid #000;
  width: 6px;
  height: 100%;
}

.matrix-div:before {
  left: -0.2em;
  border-right: 0px;
}

.matrix-div:after {
  right: -0.2em;
  border-left: 0px;
}

.matrix-div td {
  padding: 0.2em;
  padding-block: 0.1em;
  text-align: center;
}

.matrix-div sup {
  padding-left: 0.25em;
  font-size: 0.75em;
}

.matrix-eq-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-matrix {
  max-height: 100em;
  overflow: auto;
  font-size: 0.8em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.fraction-container {
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.fraction-top {
  width: 100%;
  border-bottom: solid 2px;
}

.konvajs-content {
  page-break-inside: avoid;
}
