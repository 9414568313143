.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.page-top-padding {
  padding: 5px;
}

div.main-text-container {
  padding-top: 12px;
  padding-bottom: 24px;
}

div.justify-apart {
  justify-content: space-between;
}
