.print-only-calc-report {
  display: none;
}

.show-calc-report {
  display: block;
}

@media print {
  .print-only-calc-report {
    display: block;
  }
}

.stacked-buttons button {
  margin-bottom: 0.5em;
}
